<!-- v-if="openPopup === true" -->
<template>
    <div class="bg" :class="openPopup === true ? 'full' : 'fit'">
        <h1 class="title-form">Мадлоба, генацвале!</h1>
        <div class="container">
            <div v-if="openPopup === true">
                <p class="checkLabel">{{ popupText }}</p>
            </div>
            <form @submit.prevent="sendResult" :class="displayClass">
                <label for="range-grade" class="form-grade">
                    Что больше всего повлияло на вашу оценку, дарагие генацвале?
                </label>
                <p class="checkLabel">
                    10 — великолепно! Буду рекомендовать всем! 1 — ужасно!
                    никому не рекомендую...
                </p>
                <v-slider
                    id="range-grade"
                    color="#000"
                    track-color="#b2b2b2"
                    :step="0.1"
                    :min="0.1"
                    :max="1"
                    thumb-label="always"
                    v-model="rangeInputValue"
                    class="mt"
                >
                    <template v-slot:thumb-label="{ modelValue }">
                        {{ modelValue * 10 }}
                    </template>
                </v-slider>
                <div class="checkDiv align-between">
                    <p class="checkLabel">1</p>
                    <p class="checkLabel">10</p>
                </div>
                <!-- badthings section -->
<!--                <div v-if="rangeInputValue < 0.7" class="mb">-->
<!--                    <label for="range-grade">-->
<!--                        Что больше всего повлияло на вашу оценку, дарагие-->
<!--                        генацвале? Можно выбрать несколько ответов.-->
<!--                    </label>-->
<!--                    <v-checkbox-->
<!--                        v-for="item in checkboxArr"-->
<!--                        :key="item.id"-->
<!--                        :label="item.value"-->
<!--                        :value="item.value"-->
<!--                        v-model="checkboxInputs"-->
<!--                    ></v-checkbox>-->
<!--                </div>-->
                <!-- comment section -->
                <div class="comment-wrapper">
                    <label for="comment" class="mb">
                        Комментарий
                    </label>
                    <div v-if="!commentValid && formStatus === 'sent'" class="comment-validation">
                        Комментарий должен быть не меньше 10 букв
                    </div>
                    <textarea
                        v-model="textareaValue"
                        name="text-comment"
                        id="comment"
                        cols="30"
                        rows="2"
                        class="mb"
                    ></textarea>
                </div>
                <!-- phone section -->
                <div>
                    <label for="comment" class="mb">
                        Место для вашего номера телефонидзэ
                    </label>
                    <input
                        v-model="phoneInputValue"
                        type="text"
                        name="phone-input"
                        id="phone"
                        class="mb"
                        :required="rangeInputValue <= 0.6"
                    />
                </div>
                <button type="submit" class="btn mt-10">Отправить</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            popupText: "Спасибо, что помогаете дому Хванча стать лучше❤️",
            openPopup: false,
            urlPopup: false, //  true
            rangeInputValue: 0.5,
            checkboxInputs: [],
            textareaValue: "",
            formStatus: "init",
            phoneInputValue: "",
            checkboxArr: [
                { id: "drinks", value: "Некачественные блюда и напитки" },
                { id: "assortment", value: "Маленький ассортимент" },
                {
                    id: "drinks",
                    value: "Ошибка в заказе: принесли лишнее блюдо или не донесли, что заказывали",
                },
                {
                    id: "hostess",
                    value: "Плохая работа хостес: бронирование стола, как встретили",
                },
                {
                    id: "long_time",
                    value: "Долго несли блюдо, принесли холодное блюдо, не было блюда, которое я хотел заказать",
                },
                { id: "service", value: "Слишком навязчивое обслуживание" },
                { id: "congratulation", value: "Меня не поздравили" },
                { id: "expensive", value: "Цена — слишком дорого" },
                {
                    id: "lighting",
                    value: "Темно в зале/слишком яркое освещение",
                },
                { id: "loud", value: "Слишком шумно, слишком громко" },
                {
                    id: "nursery",
                    value: "Скудное наполнение детской комнаты, не нравится работа аниматора",
                },
                { id: "other", value: "Другое — напишу в комментарии" },
            ],
        };
    },
    mounted() {
        document.title = "Отзыв о застолье в ресторане Хванч"
        setTimeout(() => {
            this.rangeInputValue = 0.7;
        }, 400);

        setTimeout(() => {
            this.rangeInputValue = 0.6;
        }, 800);
    },
    methods: {
        // async
        sendResult() {
            this.formStatus = "sent";

            if (this.textareaValue.length < 10) {
                return;
            }


            let msgForTG = "";

            if (0.8 < this.rangeInputValue) {
                msgForTG += "Положительный отзыв";
                msgForTG += `\n`;
                msgForTG += `Оценка посетителя: <b>${
                    this.rangeInputValue * 10
                }</b>\n`;
                msgForTG += `Комментарий: <b>${
                    this.textareaValue === ""
                        ? "Гость не оставил комментарий"
                        : this.textareaValue
                }</b>\n`;

                this.popupText =
                    "Хванч, счастлив стать вашим другом! До новых встреч в атмосфере веселидзэ!❤️";
                this.urlPopup = true;
            }

            if (0.6 < this.rangeInputValue && this.rangeInputValue < 0.9) {
                msgForTG += "Нейтральный отзыв";
                msgForTG += `\n`;
                msgForTG += `Оценка посетителя: <b>${
                    this.rangeInputValue * 10
                }</b>\n`;
                msgForTG += `Комментарий: <b>${
                    this.textareaValue === ""
                        ? "Гость не оставил комментарий"
                        : this.textareaValue
                }</b>\n`;
                msgForTG += `Телефон для связи: <b>${
                    this.phoneInputValue === ""
                        ? "Гость не оставил свой телефон"
                        : this.phoneInputValue
                }</b>\n`;

                this.popupText =
                    "Спасибо, что помогаете дому Хванч стать лучше❤️";
            }

            if (this.rangeInputValue <= 0.6) {
                msgForTG += "Отрицательный отзыв";
                msgForTG += `\n`;
                msgForTG += `Оценка посетителя: <b>${
                    this.rangeInputValue * 10
                }</b>\n`;
                msgForTG += `Что не понравилось:\n <b>${this.checkboxInputs.join(
                    ";\n",
                )}</b>\n`;
                msgForTG += `Комментарий: <b>${
                    this.textareaValue === ""
                        ? "Гость не оставил комментарий"
                        : this.textareaValue
                }</b>\n`;
                msgForTG += `Телефон для связи: <b>${this.phoneInputValue}</b>\n`;

                this.popupText =
                    "Спасибо, что помогаете дому Хванч стать лучше❤️";
            }

            msgForTG += `\n`;

            msgForTG += `Время отправки отзыва: <b>${new Date().toLocaleTimeString()}</b>\n`;
            msgForTG += `Дата отправки заявки: <b>${new Date().toLocaleDateString()}</b>`;

            // await
            axios.post(process.env.VUE_APP_URL_TELEGRAM, {
                chat_id: process.env.VUE_APP_HVANCH_28_CHAT_ID,
                parse_mode: "html",
                text: msgForTG,
            });

            const formData = new FormData();

            formData.append(
                "feedback",
                0.8 < this.rangeInputValue
                    ? "Положительный отзыв"
                    : 0.6 < this.rangeInputValue && this.rangeInputValue < 0.9
                    ? "Нейтральный отзыв"
                    : "Отрицательный отзыв",
            );
            formData.append("grade", this.rangeInputValue * 10);
            formData.append(
                "problems",
                0.8 < this.rangeInputValue
                    ? "Нет проблем"
                    : 0.6 < this.rangeInputValue && this.rangeInputValue < 0.9
                    ? "Проблема описана в комментарии"
                    : `${this.checkboxInputs.join(";\n")}`,
            );
            formData.append("comment", this.textareaValue);
            formData.append(
                "phone",
                this.phoneInputValue === ""
                    ? "Гость не оставил свой телефон"
                    : this.phoneInputValue,
            );
            formData.append(
                "time",
                `${new Date().toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`,
            );
            formData.append("date", `${new Date().toLocaleDateString()}`);

            axios
                .post(process.env.VUE_APP_URL_TO_GS_28, formData)
                .then(result => {
                    console.log("Данные успешно отправлены в таблицу:", result);
                })
                .catch(error => {
                    console.error(
                        "Ошибка при отправке данных в таблицу:",
                        error,
                    );
                });

            document.querySelector(".bg").style.height = "calc(100% + 450px);";
            this.openPopup = true;

            this.rangeInputValue = 0.5;
            this.checkboxInputs = [];
            this.textareaValue = "";
            this.phoneInputValue = "";
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
    },
    computed: {
        displayClass() {
            if (this.openPopup === true) {
                return "none";
            } else {
                return "display";
            }
        },
        commentValid() {
            return this.textareaValue.length >= 10;
        }
    },
    watch: {
        phoneInputValue() {
            this.phoneInputValue = this.phoneInputValue.replace(
                /[a-zа-яё]/gi,
                "",
            );

            this.phoneInputValue[0] === "7"
                ? (this.phoneInputValue = this.phoneInputValue[0].replace(
                      "7",
                      "8",
                  ))
                : (this.phoneInputValue = this.phoneInputValue);

            if (this.phoneInputValue.length > 11) {
                this.phoneInputValue = "";
            }
        },
    },
};
</script>

<style scoped>
.bg {
    background-image: url("../assets/img/podloshkajpeg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100%;
    max-height: fit-content;
    width: 100%;
    padding: 50px 0;
}

.link {
    color: #000000;
    text-decoration: none;
    font-size: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.link:hover {
    color: #64dcc3;
}

.url_btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.icon {
    width: 30px;
    height: 30px;
    border: 0;
    border-radius: 50px;
}

@media (max-width: 500px) {
    .url_btns {
        flex-direction: column;
        gap: 10px;
    }
}

.title-form {
    font-size: 100px;
    text-align: center;
    font-family: "Amatic SC", cursive;
    text-transform: uppercase;
}

.container {
    width: 700px;
    margin: 20px auto 250px;
    background-color: white;
    padding: 40px;
    box-sizing: border-box;
    border: 0;
    border-radius: 60px;
}

@media (max-width: 700px) {
    .container {
        width: 500px;
    }

    .title-form {
        font-size: 80px;
    }
}

@media (max-width: 500px) {
    .container {
        width: 340px;
    }

    .title-form {
        font-size: 48px;
    }
}

.comment {
    border: 2px solid black;
    border-radius: 20px;
}

label {
    display: block;
    color: rgb(0, 0, 0);
    font-family: PT Sans Narrow, serif;
    font-size: 26px;
    font-weight: 700;
}

.mb {
    margin-bottom: 16px;
}
.mt {
    margin-top: 26px;
}

#comment,
#phone {
    color: rgb(0, 0, 0);
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

#comment {
    height: 85px;
}

#phone {
    height: 55px;
}

form {
    transition: all 5s ease-out;
}

.form-grade {
    padding-bottom: 12px;
}

label {
    line-height: 1;
}

.btn {
    padding: 0px 15px;
    display: block;
    width: 220px;
    font-weight: 600;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    color: #000000;
    border-radius: 30px;
    font-size: 22px;
    font-family: PT Sans Narrow, serif;
    cursor: pointer;
    background-color: #e6ff00;
}

.checkLabel,
.v-label {
    font-size: 18px;
    font-weight: 400;
    font-family: PT Sans Narrow, serif;
    color: #000000 !important;
    opacity: 1;

    @media (max-width: 750px) {
        line-height: 1;
    }
}

.checkDiv {
    margin-top: -24px;
    margin-bottom: 20px;
}

.v-input__details {
    display: none;
}

.v-alert__content {
    font-family: PT Sans Narrow, serif;
    color: #000000;
    font-size: 22px;
}

.comment-wrapper {
    position: relative;
}

.comment-validation {
    color: tomato;
    font-size: 14px;
    font-family: PT Sans Narrow, serif;
    margin-left: auto;
    margin-right: 12px;
    width: fit-content;

    @media (max-width: 500px) {
        top: 40px;
    }
}
</style>
