import { createRouter, createWebHashHistory } from 'vue-router'
import Perk from '../views/PerkNps.vue'
import UzbechkaSaratov7Nps from '../views/UzbechkaSaratov7Nps.vue'
// import UzbechkaSaratov7NpsNew from '../views/UzbechkaSaratov8NpsNew.vue'
import UzbechkaSaratov8Nps from '../views/UzbechkaSaratov8Nps.vue'
// import UzbechkaSaratov8NpsNew from '../views/UzbechkaSaratov8NpsNew.vue'
import UzbechkaPenza17Nps from "../views/UzbechkaPenza17Nps.vue";
// import UzbechkaPenza17NpsNew from '../views/UzbechkaPenza17NpsNew.vue'
import UzbechkaPenza26Nps from '../views/UzbechkaPenza26Nps.vue'
// import UzbechkaPenza26NpsNew from '../views/UzbechkaPenza26NpsNew.vue'
import Djos from '../views/DjosNps.vue'
import Hvanch from '../views/HvanchNps.vue'
import HvanchVolgograd from '../views/HvanchVolgogradNps.vue'

function updateFavicon(iconUrl) {
  let link = document.querySelector("link[rel~='icon']");

  if (link) {
    document.head.removeChild(link); // Удаляем старый favicon
  }

  link = document.createElement('link');
  link.rel = 'icon';
  link.href = iconUrl;
  document.head.appendChild(link);
}

const routes = [
  {
    path: '/',
    name: 'DJOS',
    component: Djos,
    meta: { favicon: "/hvanch-favicon.ico" }
  },
  {
    path: '/hvanch_23',
    name: 'HVANCH_23',
    component: Hvanch,
    meta: { favicon: "/hvanch-favicon.ico" }
  },
  {
    path: '/hvanch_25',
    name: 'HVANCH25',
    component: Djos,
    meta: { favicon: "/hvanch-favicon.ico" }
  },
  {
    path: '/hvanch_28',
    name: 'HVANCH',
    component: HvanchVolgograd,
    meta: { favicon: "/hvanch-favicon.ico" }
  },
  {
    path: '/hvanch',
    name: 'HVANCH-23',
    component: Hvanch,
    meta: { favicon: "/hvanch-favicon.ico" }
  },
  {
    path: '/perk',
    name: 'PERK',
    component: Perk,
    meta: { favicon: "/perk-favicon.ico" }
  },
  {
    path: '/uzbechka_saratov_7',
    name: 'UZBECHKA_SAR_7',
    component: UzbechkaSaratov7Nps,
    meta: { favicon: "/uzbechka-favicon.ico" }
  },
  {
    path: '/uzbechka_saratov_8',
    name: 'UZBECHKA_SAR_8',
    component: UzbechkaSaratov8Nps,
    meta: { favicon: "/uzbechka-favicon.ico" }
  },
  {
    path: '/uzbechka_penza_17',
    name: 'UZBECHKA_PENZA_17',
    component: UzbechkaPenza17Nps,
    meta: { favicon: "/uzbechka-favicon.ico" }
  },
  {
    path: '/uzbechka_penza_26',
    name: 'UZBECHKA_PENZA_26',
    component: UzbechkaPenza26Nps,
    meta: { favicon: "/uzbechka-favicon.ico" }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  // Изменение favicon
  if (to.meta.favicon) {
    updateFavicon(to.meta.favicon);
  }

  next();
});

export default router